import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      es: {
        translation: {
          headerIntro: "Soluciones de impacto socioambiental",
          description: "Sistema de climatización natural que mejora la calidad de vida de clientes y trabajadores del transporte público colectivo.",
          contact: "Contáctanos",
          advantages: "¡Conoce nuestras ventajas!",
          social: "Impacto Social",
          satisfied: "9 de cada 10 personas están satisfechas con la sensación térmica",
          environmentalImpact: "Impacto Ambiental",
          pollutantEmissions: "Cero impacto en el consumo de combustible y emisiones de contaminantes",
          health: "Salud",
          ANVISA: "Buen nivel de calidad del aire según Resolución Nº09 de ANVISA",
          safety: "Seguridad",
          nbr: "Seguro y en conformidad con la norma NBR 15570",
          economical: "Económico",
          lowAcquisition: "Bajo costo de adquisición y mantenimiento",
          passenger2Passenger: "Una solución de pasajero para pasajero",
          leoPhrase: "Desarrollamos Arejabus porque sufrimos con el calor excesivo en los autobuses y conocemos el impacto social de las tarifas.” – Leonardo Santiago, Cofundador de Areja",
          faq: "Preguntas Frecuentes",
          question_1: "¿Cómo Funciona?",
          resp_1: "Arejabus utiliza el propio movimiento del autobús para renovar el aire mediante dispositivos naturales, y en momentos de baja velocidad, un controlador activa la ventilación forzada para mantener una buena sensación térmica y calidad del aire.",
          question_2: "¿Funciona en días de lluvia?",
          resp_2: "Sí, el flujo de aire ocurre independientemente de las condiciones climáticas y operativas.",
          question_3: "¿Cómo podemos aplicar este producto?",  
          resp_3: "El equipo de Areja desarrolla un proyecto de climatización personalizado para cada modelo de autobús, permitiendo que Arejabus sea aplicado tanto en vehículos nuevos como usados.",
          cont: "Contacto",
          successStories: "Nuestros Casos de Éxito",
          firstVersion: "Primera versión de Arejabus – 2020",
          secondVersion: "Segunda versión de Arejabus – 2023",
          thirdVersion: "Tercera versión de Arejabus – 2023",
          latestVersion: "Última versión de Arejabus – 2023",
          forColdRegions: "Versión de Arejabus para regiones frías – 2023",
          latestVersion24: "Última versión de Arejabus - 2024",
          versionCaio: "Primer lote en Caio Induscar - 2024"
        }
      },
      en: {
        translation: {
          headerIntro: "Socio-environmental Impact Solutions",
          description: "Natural climate control system that improves the quality of life for customers and workers in public transportation.",
          contact: "Contact Us",
          advantages: "Check out our unique advantages!",
          social: "Social Impact",
          satisfied: "9 out of 10 people are satisfied with the thermal comfort",
          environmentalImpact: "Environmental Impact",
          pollutantEmissions: "Zero impact on fuel consumption and pollutant emissions",
          health: "Health",
          ANVISA: "Good air quality level as per ANVISA Resolution No. 09",
          safety: "Safety",
          nbr: "Safe and compliant with NBR 15570",
          economical: "Economical",
          lowAcquisition: "Low acquisition and maintenance costs",
          passenger2Passenger: "A passenger-to-passenger solution",
          leoPhrase: "We developed Arejabus because we struggled with the excessive heat on buses and understand the social impact of fares.” – Leonardo Santiago, Co-founder of Areja",
          faq: "Frequently Asked Questions",
          question_1: "How Does It Work?",
          resp_1: "Arejabus uses the bus’s own movement to renew air through natural devices, and at low speeds, a controller activates forced ventilation to maintain thermal comfort and air quality.",
          question_2: "Does it work on rainy days?",
          resp_2: "Yes, the airflow occurs regardless of weather and operating conditions.",
          question_3: "How can we apply this product?", 
          resp_3: "The Areja team develops a custom climate control design for each bus model, allowing Arejabus to be applied to both new and used vehicles.",
          cont: "Contact",
          successStories: "Our Success Stories",
          firstVersion: "First version of Arejabus – 2020",
          secondVersion: "Second version of Arejabus – 2023",
          thirdVersion: "Third version of Arejabus – 2023",
          latestVersion: "Latest version of Arejabus – 2023",
          forColdRegions: "Arejabus version for cold regions – 2023",
          latestVersion24: "Latest version of Arejabus - 2024",
          versionCaio: "First batch at Caio Induscar - 2024"
        }
      },
      pt: {
        translation: {
          headerIntro: "Soluções de impacto socioambiental",
          description: "Sistema de climatização natural que melhora a qualidade de vida de clientes e trabalhadores do transporte público coletivo.",
          contact: "Entre em contato",
          advantages: "Confira os nossos diferenciais!",
          social: "Impacto Social",
          satisfied: "9 a cada 10 pessoas estão satisfeitas com a sensação térmica",
          environmentalImpact: "Impacto Ambiental",
          pollutantEmissions: "Zero impacto no consumo de combustível e emissões de poluentes",
          health: "Saúde",
          ANVISA: "Bom nível de qualidade de ar conforme Resolução Nº09 da ANVISA",
          safety: "Segurança",
          nbr: "Seguro por atender a NBR 15570",
          economical: "Econômico",
          lowAcquisition: "Baixo custo de aquisição e manutenção",
          passenger2Passenger: "Uma solução de passageiro para passageiro",
          leoPhrase: "Desenvolvemos o Areajbus porque sofremos com o calor excessivo dos ônibus e sabemos o impacto social das tarifas” Leonardo Santiago, Cofundador da Areja",
          faq: "Perguntas Frequentes",
          question_1: "Como Funciona ?",
          resp_1: "O Arejabus utiliza a própria movimentação do ônibus para promover a renovação de ar por meio de dispositivos naturais e nos momentos de baixa velocidade, um controlador aciona a ventilação forçada para manter o bom nível de sensação térmica e qualidade de ar.",
          question_2: "Funciona em dias de chuva ?",
          resp_2: "Sim, o fluxo de ar acontece independentemente das condições climáticas e operacionais.",
          question_3: "Como podemos aplicar esse produto ?",
          resp_3: "A equipe da Areja desenvolve um projeto de climatização para cada modelo de ônibus, possibilitando que o Arejabus seja aplicado em veículos novos ou usados.", 
          cont: "Contato",
          successStories: "Nossos Cases de Sucesso",
          firstVersion: "Primeira versão do Arejabus - 2020",
          secondVersion: "Segunda versão do Arejabus - 2023",
          thirdVersion: "Terceira versão do Arejabus - 2023",
          latestVersion: "Última versão do Arejabus - 2023",
          forColdRegions: "Versão do Arejabus para regiões frias - 2023",
          latestVersion24: "Última versão do Arejabus - 2024",
          versionCaio: "Primeiro lote na Caio Induscar - 2024"
        }
      }
    },
    lng: "pt", // Idioma padrão
    fallbackLng: "pt",
    interpolation: {
      escapeValue: false // React já faz a sanitização das strings
    }
  });

export default i18n;
